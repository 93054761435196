import 'core-js/stable';
import 'core-js/es/object';
import 'regenerator-runtime/runtime';
import Rails from '@rails/ujs';
import $ from 'jquery';
$.rails = Rails;

import 'jquery-ui';
import '@popperjs/core';
import 'bootstrap';
import 'datatables.net-bs5';
import 'dayjs';
import 'jquery-ui/ui/widgets/datepicker';

Rails.start();

import dayjs from 'dayjs';
window.dayjs = dayjs;

window.$ = $;
window.jQuery = $;


$(document).ajaxSend(function (event, xhr, settings) {
  const token = $('meta[name="csrf-token"]').attr('content');
  if (token) {
    xhr.setRequestHeader('X-CSRF-Token', token);
  }
});
